
<template>
    <v-container style="width:70%; height:100%; padding-top:5%;">
        <v-container style="width:80%; height:40%;">
            <v-card style="display:flex; background:#f2f2f1; border:none;">
                <v-card-actions>
                    <v-icon
                        large
                        colo="green"
                        style="font-size:8vh; color:#2A5786; "
                    >
                        mdi-account-circle
                    </v-icon>
                </v-card-actions>
                <div>
                    <v-card-text style="font-size:24px; font-weight:bold; color:#405E97; padding: 0; padding-top:20%;">
                        {{this.user_name}} 님
                    </v-card-text>
                    <v-card-text style="font-weight:bold; color:#5A8EF6; padding: 0; padding-top:5%;">
                        환영합니다!
                    </v-card-text>
                </div>
                <v-spacer></v-spacer>
                <div>
                <td style="font-size:16px; font-weight:bold; color:#405E97; padding: 0; padding-top:20%;">
                 {{this.codeType}}  &nbsp;&nbsp;
                </td>
            </div>
                <v-btn
                    color='blue'
                    style="position:absolute; top:55%; right:0; color:white; margin-right:10px;"
                    width="220px"
                    @click="dialogScanSetting = true"
                >
                    스캔 설정
                </v-btn>
            </v-card>
            <div style="height:5%;text-align:right;">
                <v-card-text style="font-size:16px; font-weight:bold; color:#405E97; padding: 0; padding-top:20%;">
                   
                </v-card-text>
            </div>
            <div style="width:100%; height:30%; background:white; color:#2A5786;">
                재고관리 시스템을 이용하기 위해<br>품목정보와 위치정보를 설정하는 화면입니다.
            </div>
        </v-container>
        
        <v-container style="width:100%; height:50%; display:flex;" >
            <v-card style="width:60%; height:100%; text-align:center;">
                <v-card-title style="justify-content: center;font-weight: bold;" >품목</v-card-title>
                <v-card-text style="display:flex; margin-top:3%;">
                    <v-sheet
                        class="d-flex"
                        color="white lighten-3"
                        width="30%"
                        height="150px"
                        style="justify-content: center; align-items: center;"
                        @click="uploadExcelItem"
                        :elevation="hover1 ? '10' : '3'"
                    >
                        <div id="" @mouseover="hover1 = true" @mouseleave="hover1 = false"  >
                            <div style="width:100%; height:80%;" >
                                      <img src="../../assets/icons/excel.png" :style="{width:hover1?'70%':'50%'}" />
                            </div>
                            <div style="width:100%; height:20%; text-align:right;">
                                <v-icon
                                    large
                                    :color="this.hover1?'blue':'black'"
                                    style="color:#2A5786; "
                                >
                                    mdi-tray-arrow-up
                                </v-icon>
                            </div>
                        </div>
                    </v-sheet>
                    <v-sheet
                        class="d-flex"
                        color="white lighten-3"
                        width="30%"
                        height="150px"
                        style="margin-left:5%; justify-content: center; align-items: center;"
                        @click="templateExcelItem"
                        :elevation="hover2 ? '10' : '3'"
                    >
                        <div @mouseover="hover2 = true" @mouseleave="hover2 = false">
                            <div style="width:100%; height:80%;">
                                <img src="../../assets/icons/excel.png" :style="{width:hover2?'70%':'50%'}" />
                            </div>
                            <div style="width:100%; height:20%; text-align:right;">
                                <v-icon
                                    large
                                    :color="this.hover2?'blue':'black'"
                                    style="color:#2A5786; "
                                >
                                    mdi-tray-arrow-down
                                </v-icon>
                            </div>
                        </div>
                    </v-sheet>

                    <v-sheet
                        class="d-flex"
                        color="white lighten-3"
                        width="30%"
                        height="150px"
                        style="margin-left:5%; justify-content: center; align-items: center;"
                        @click="downloadExcelItem"
                        :elevation="hover3 ? '10' : '3'"
                    >
                        <div @mouseover="hover3 = true" @mouseleave="hover3 = false">
                            <div style="width:100%; height:80%;">
                                <img src="../../assets/icons/excel.png" :style="{width:hover3?'70%':'50%'}" />
                            </div>
                            <div style="width:100%; height:20%; text-align:right;">
                                <v-icon
                                    large
                                    :color="this.hover3?'blue':'black'"
                                    style="color:#2A5786; "
                                >
                                    mdi-barcode
                                </v-icon>
                            </div>
                        </div>
                    </v-sheet>
                </v-card-text>
                <div style="width:100%; display:flex;">
                    <div style="width:33%;">
                        <v-card-text style="text-align:center; color:#5A8EF6;font-weight: bold;" >템플릿 업로드</v-card-text>
                    </div>
                    <div style="width:33%;">
                        <v-card-text style="text-align:center; color:#5A8EF6;font-weight: bold;">템플릿 다운로드</v-card-text>
                    </div>
                    <div style="width:33%;">
                        <v-card-text style="text-align:center; color:#5A8EF6;font-weight: bold;">{{this.codeType}} 템플릿<br>다운로드</v-card-text>
                    </div>
                </div>
            </v-card>
            <v-card style="width:40%; height:100%; margin-left:10%; text-align:center;">
                <v-card-title style="justify-content: center;font-weight: bold;">위치정보</v-card-title>
                <v-card-text style="display:flex; margin-top:3%;">
                      <v-sheet 
                        class="d-flex"
                        color="gray lighten-3"
                        width="50%"
                        height="150px"
                        style="justify-content: center; align-items: center;"
                        @click="dialogLocation = true"
                        :elevation="hover4 ? '10' : '3'"
                    >
                        <div style="width:100%;" @mouseover="hover4 = true" @mouseleave="hover4 = false" >
                            <div style="width:100%; height:80%;">
                                <v-icon size="50px" color="blue"  :style="{fontSize:hover4?'70px':'50px'}">mdi-map-marker</v-icon>
                            </div>
                            <div style="width:100%; height:20%; text-align:right;">
                                <v-icon
                                    large
                                    :color="this.hover4?'green':'black'"
                                    style="color:#2A5786; "
                                >
                                    mdi-plus-circle-outline
                                </v-icon>
                            </div>
                        </div>
                    </v-sheet>
                    <v-sheet
                        class="d-flex"
                        color="gray lighten-3"
                        width="50%"
                        height="150px"
                        style="margin-left:10%; justify-content: center; align-items: center;"
                        @click="dialogUidWhouse = true"
                        :elevation="hover5 ? '10' : '3'"
                        v-show="this.typeLblWhouse(this.$store.getters.getCodeFormat)"
                    >
                        <div @mouseover="hover5 = true" @mouseleave="hover5 = false" >
                            <div style="width:100%; height:80%;">
                                <img src="../../assets/icons/excel.png" :style="{width:hover5?'70%':'50%', height:hover5?'70%':'50%'}" />
                            </div>
                            <div style="width:100%; height:20%; text-align:right;">
                                <v-icon
                                    large
                                    :color="this.hover5?'blue':'black'"
                                    style="color:#2A5786; "
                                >
                                    mdi-barcode
                                </v-icon>
                            </div>
                        </div>
                    </v-sheet>
                </v-card-text>
                <div style="width:100%; display:flex;">
                    <div style="width:50%;">
                        <v-card-text style="text-align:center; color:#5A8EF6;font-weight: bold;">위치정보 등록</v-card-text>
                    </div>
                    <div style="width:50%;">
                        <v-card-text style="text-align:center; color:#5A8EF6;font-weight: bold;">{{this.codeType}} 템플릿<br>다운로드</v-card-text>
                    </div>
                </div>
            </v-card>
        </v-container>

        <v-dialog
            v-model="dialogLocation"
            max-width="1000px"
            scrollable
        >
            <div style="width:100%; height:100%; background:white;">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="regist_wh_code"
                                :label="lblWhouse"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="regist_rack_code"
                                :label="lblRack"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="regist_col"
                                type="number"
                                label="*열"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="regist_row"
                                type="number"
                                label="*층"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="6" align="center">
                            <v-btn
                                tile
                                color="primary"
                                @click="registLocation"
                            >
                                확인
                            </v-btn>
                        </v-col>
                        <v-col cols="6" align="center">
                            <v-btn
                                tile
                                color="error"
                                @click="dialogLocation = false"
                            >
                                취소
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-dialog>

        <v-dialog
            v-model="dialogUidWhouse"
            max-width="1000px"
            scrollable
        >
            <div style="width:100%; height:100%; background:white;">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                v-model="selectedUidWhouse"
                                label="*창고선택"
                                :items="whouseDatas"
                                menu-props="auto"
                                outlined
                                hide-details
                                item-text="wh_code"
                                item-value="unique_id"
                                @change="clickWhouse"
                                @click="inputClickWhouse"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="6" align="center">
                            <v-btn
                                tile
                                color="primary"
                                @click="excelLocationBarcode"
                            >
                                확인
                            </v-btn>
                        </v-col>
                        <v-col cols="6" align="center">
                            <v-btn
                                tile
                                color="error"
                                @click="dialogUidWhouse = false"
                            >
                                취소
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-dialog>
        <v-dialog
            v-model="dialogScanSetting"
            width="30%"
            max-width="1000px"
            scrollable
        >
            <div style="width:100%; height:100%; background:white;">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                v-model="codeType"
                                label="*코드선택"
                                :items="codeTypes"
                                menu-props="auto"
                                outlined
                                hide-details
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="6" align="center">
                            <v-btn
                                tile
                                color="primary"
                                @click="updateCodeType"
                            >
                                확인
                            </v-btn>
                        </v-col>
                        <v-col cols="6" align="center">
                            <v-btn
                                tile
                                color="error"
                                @click="dialogScanSetting = false"
                                @mousedown="this.init"
                            >
                                취소
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-dialog>

        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>

        <v-snackbar
            v-model="snackbar"
            :top="true"
            :color="snackResult"
            :timeout="3000"
        >
            <div style="text-align:center;">{{snackText}}</div>
            <template v-slot:action="{ attrs }">
                <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>

    </v-container>
</template>

<script>
import axios from 'axios'

export default {
    name:'Standard',
    data() {
        return {
            lblWhouseTitle:'',
            lblWhouse:'',
            lblRack:'',
            
            pictureStatic: require("../../assets/icons/excel.png"),
            pictureGif: require("../../assets/icons/add-item.png"),
            hover1: false,
            hover2: false,
            hover3: false,
            hover4: false,
            hover5: false,

            uid_company:null,
            user_uid:null,
            user_id:null,
            user_name:null,
            codeType:null,
            
            dialogLogout:false,
            dialogLocation:false,
            dialogUidWhouse:false,
            dialogCodeType:false,
            dialogScanSetting:false,

            regist_wh_code:null,
            regist_rack_code:null,
            regist_row:null,
            regist_col:null,

            snackbar:false,
            snackResult:null,
            snackText:null,
            
            overlay: false,

            selectedUidWhouse:null,
            whouseDatas:[],

            selectedCodeType:'BARCODE',
            codeTypes:['BARCODE','QRCODE','RAZER'],
        }
    },

    mounted() {
        this.init();

        if(this.$store.getters.getUidCompany==101000002070){ //대주전자 uid_company
            this.$store.commit('setCodeFormat', 1);
        }
        else{
            this.$store.commit('setCodeFormat', 0);
        }
        this.$store.commit('setCodeFormat', 0); // ##DBG : 일단 0으로 고정
    },
    components: {

    },
    watch: {
        dialogLocation() {
            if(!this.dialogLocation) {
                this.regist_wh_code = null;
                this.regist_rack_code = null;
                this.regist_row = null;
                this.regist_col = null;
            }
        },
        dialogUidWhouse() {
            if(!this.dialogUidWhouse) {
                this.selectedUidWhouse = null;
            }
        }
    },
    computed: {
        pictureHover () {
            if (this.hover == true) {
                return this.pictureGif
            } else {
                return this.pictureStatic
            }
        }
    },    
    methods: {
        typeLblWhouse(val) {
            switch(val) {
            case 0:
                this.lblWhouseTitle = ""
                this.lblWhouse = "*창고(2자리)"
                this.lblRack = "*동(1~4자리)"
                break;
            case 1:
            this.lblWhouseTitle = "구분자 '-' 제외 입력"
                this.lblWhouse = "*창고"
                this.lblRack = "*동"
                break;
            }
        }   ,

        init() {
            const userInfo = this.$store.getters.getLoginDatas;
            this.uid_company = userInfo.uid_company
            this.user_uid = userInfo.user_uid
            this.user_id = userInfo.user_id
            this.user_name = userInfo.user_name
            this.codeType = userInfo.codeType
        },
        goToMenu(path) {
            this.$router.push(path);
        },
        logout() {
            this.dialogLogout = true
        },
        logout_confirm() {
            this.dialogLogout = false
            this.$store.commit('clearUserInfo')
            this.$router.replace('login')
        },
        checkUserGrade() {
            const user_id = this.$store.getters.getUserId
            if(user_id!=null && (user_id == 'admin' || user_id == 'root')) {
                return false
            } else {
                return true
            }
        },
        uploadExcelItem() {
            let input = document.createElement('input')

            input.id = 'excel'
            input.type = 'file'
            input.accept = 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            input.multiple = true

            input.click();

            var url = this.$vBACKEND_URL + '/item.do?method=itemExcelUpload'
            var me = this

            // Event
            input.onchange = function() {
            const formData = new FormData()
            formData.append('file', this.files[0])
            formData.append('uid_company', me.$store.getters.getUidCompany)
            formData.append('user_uid', me.$store.getters.getUserUid)
            formData.append('user_id', me.$store.getters.getUserId)
            formData.append('user_name', me.$store.getters.getUserName)

            axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
                        me.snackbar = true;
                        me.snackResult = '#60C5F1';
                        me.snackText = '성공'
                    }).catch(err => {
                        console.log(err)
                        me.snackbar = true;
                        me.snackResult = "red";
                        me.snackText = '실패'
                    })
            }
        },

        templateExcelItem() {
            var url = this.$vBACKEND_URL + '/item.do?method=itemTemplateDownLoad';
            var obj = {
                uid_company:this.$store.getters.getUidCompany,
            }
            var params = '';
            for(var key in obj) {
            params += '&' + key + '=' + obj[key]
            }
            url += params;

            
            fetch(url)
            .then(res => res.clone().json())
            .then(res => {
                const excelPath = res.result;
                if(excelPath!=null) {
                    this.snackbar = true;
                    this.snackResult = '#60C5F1';
                    this.snackText = '성공'
                    location.href = this.$vCONTENT_URL + "/excel/" + excelPath
                } else {
                    this.snackbar = true;
                    this.snackResult = 'FFFBE5';
                    this.snackText = '조회된 데이터가 없습니다.'
                }
            })
            .catch(err => {
                console.log(err)
                this.snackbar = true;
                this.snackResult = 'red';
                this.snackText = '실패'
            })
        },

        downloadExcelItem() {
            this.overlay = true
            var codeType = this.codeType;
            if(codeType == 'RAZER') codeType = 'BARCODE'

            var url = this.$vBACKEND_URL + '/item.do?method=itemExcelDownLoad';
            var obj = {
                uid_company:this.$store.getters.getUidCompany,
                codeType:codeType
            }
            var params = '';
            for(var key in obj) {
                params += '&' + key + '=' + obj[key]
            }
            url += params;

            fetch(url)
            .then(res => res.clone().json())
            .then(res => {
                const excelPath = res.result;
                if(excelPath!=null) {
                    this.snackbar = true;
                    this.snackResult = '#60C5F1';
                    this.snackText = '성공'
                    location.href = this.$vCONTENT_URL + "/excel/" + excelPath
                } else {
                    this.snackbar = true;
                    this.snackResult = 'FFFBE5';
                    this.snackText = '조회된 데이터가 없습니다.'
                }
            })
            .catch(err => {
                console.log(err)
                this.snackbar = true;
                this.snackResult = 'red';
                this.snackText = '실패'
            })
            .finally(() => {
                
                this.overlay = false
            })
        },

        uploadExcelLocation() {
            let input = document.createElement('input')

            input.id = 'excel'
            input.type = 'file'
            input.accept = 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            input.multiple = true

            input.click();

            var url = this.$vBACKEND_URL + '/template.do?method=execWearingExcel'
            var me = this

            // Event
            input.onchange = function() {
                me.overlay = true
                const formData = new FormData()
                formData.append('file', this.files[0])
                formData.append('uid_company', me.$store.getters.getUidCompany)
                formData.append('user_uid', me.$store.getters.getUserUid)
                formData.append('user_id', me.$store.getters.getUserId)
                formData.append('user_name', me.$store.getters.getUserName)

                axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
                            me.snackbar = true;
                            me.snackResult = '#60C5F1';
                            me.snackText = '성공'

                            me.getDatas();
                        }).catch(err => {
                            console.log(err)
                            me.snackbar = true;
                            me.snackResult = "red";
                            me.snackText = '실패'
                        }).finally(() => {
                            me.overlay = false
                        })
            }
        },

        downloadExcelLocation() {
            var url = this.$vBACKEND_URL + '/stock.do?method=stockTemplateDownLoad';
            var obj = {
                uid_company:this.$store.getters.getUidCompany,
            }
            var params = '';
            for(var key in obj) {
            params += '&' + key + '=' + obj[key]
            }
            url += params;

            fetch(url)
            .then(res => res.clone().json())
            .then(res => {
                const excelPath = res.result;
                if(excelPath!=null) {
                    this.snackbar = true;
                    this.snackResult = '#60C5F1';
                    this.snackText = '성공'
                    location.href = this.$vCONTENT_URL + "/excel/" + excelPath
                } else {
                    this.snackbar = true;
                    this.snackResult = 'FFFBE5';
                    this.snackText = '조회된 데이터가 없습니다.'
                }
            })
            .catch(err => {
                console.log(err)
                this.snackbar = true;
                this.snackResult = 'red';
                this.snackText = '실패'
            })
        },

        excelLocationBarcode() {
            if(this.selectedUidWhouse==null || this.selectedUidWhouse<1) {
                this.snackbar = true;
                this.snackResult = 'red';
                this.snackText = '창고를 선택해주세요.'
                return
            }


            this.overlay = true
            var codeType = this.codeType;
            if(codeType == 'RAZER') codeType = 'BARCODE'

            var url = this.$vBACKEND_URL + '/template.do?method=binExcelDownload';
            var obj = {
                uid_company:this.$store.getters.getUidCompany,
                uid_whouse:this.selectedUidWhouse,
                codeType:codeType
            }
            var params = '';
            for(var key in obj) {
            params += '&' + key + '=' + obj[key]
            }
            url += params;

            this.dialogUidWhouse = false;
            this.overlay = true

            fetch(url)
            .then(res => res.clone().json())
            .then(res => {
                const excelPath = res.result;
                if(excelPath!=null) {
                    this.snackbar = true;
                    this.snackResult = '#60C5F1';
                    this.snackText = '성공'
                    location.href = this.$vCONTENT_URL + "/excel/" + excelPath
                } else {
                    this.snackbar = true;
                    this.snackResult = 'FFFBE5';
                    this.snackText = '조회된 데이터가 없습니다.'
                }
            })
            .catch(err => {
                console.log(err)
                this.snackbar = true;
                this.snackResult = 'red';
                this.snackText = '실패'
            })
            .finally(() => {
                this.overlay = false;
            })
        },

        registLocation() {
            if(this.regist_wh_code==null || this.regist_wh_code.length<1) {
                this.snackbar = true;
                this.snackResult = 'red';
                this.snackText = '창고 값을 확인해주세요.'
                return
            } else if(this.regist_wh_code.length != 2) {
                if(this.$store.getters.getCodeFormat==0){
                    this.snackbar = true;
                    this.snackResult = 'red';
                    this.snackText = '창고 값은 2자리 입니다.'
                    return
                }
            }
            if(this.regist_rack_code==null || this.regist_rack_code.length<1) {
                this.snackbar = true;
                this.snackResult = 'red';
                this.snackText = '동 값을 확인해주세요.'
                return
            } else if(this.regist_rack_code.length < 1 || this.regist_rack_code.length > 4) {
                //if(this.$store.getters.getCodeFormat==0){
                    this.snackbar = true;
                    this.snackResult = 'red';
                    //this.snackText = '동 값은 2자리 입니다.'
                    this.snackText = '동 값은 1~4자리 입니다.'
                    return
                //}
            }
            if(this.regist_row==null || this.regist_row.length<1) {
                this.snackbar = true;
                this.snackResult = 'red';
                this.snackText = '층 값을 확인해주세요.'
                return
            } else if(this.regist_row.length > 2) {
                this.snackbar = true;
                this.snackResult = 'red';
                this.snackText = '층 값은 1~99 사이만 가능합니다.'
                return
            }
            if(this.regist_col==null || this.regist_col.length<1) {
                this.snackbar = true;
                this.snackResult = 'red';
                this.snackText = '열 값을 확인해주세요.'
                return
            } else if(this.regist_col.length > 2) {
                this.snackbar = true;
                this.snackResult = 'red';
                this.snackText = '열 값은 1~99 사이만 가능합니다.'
                return
            }

            this.overlay = true

            var url = this.$vBACKEND_URL + '/stock.do?method=webRegistLocationData';
            var obj = {
                uid_company:this.$store.getters.getUidCompany,
                user_uid:this.$store.getters.getUserUid,
                user_id:this.$store.getters.getUserId,
                user_name:this.$store.getters.getUserName,

                wh_code:this.regist_wh_code,
                rack_code:this.regist_rack_code,
                row:this.regist_row,
                col:this.regist_col,
            }

            var params = '';
            for(var key in obj) {
                params += '&' + key + '=' + obj[key]
            }
            url += params;

            fetch(url)
            .then(res => {
                this.snackbar = true;
                this.snackResult = '#60C5F1';
                this.snackText = '등록 성공'
            })
            .catch(err => {console.log(err)})
            .finally(() => {
                this.overlay = false
                this.dialogLocation = false
            })

        },

        clickWhouse() {
            console.log(' ')
        },

        inputClickWhouse() {
            // 데이터  호출
            var url = this.$vBACKEND_URL + '/stock.do?method=readwhouse';
            var pObj = {
            uid_company:this.$store.getters.getUidCompany,
            }
            var params = '';
            for(var key in pObj) {
            params += '&' + key + '=' + pObj[key]
            }

            url += params;

            fetch(url)
            .then(res => res.clone().json())
            .then(res => {
                this.whouseDatas = res.datas
            })
            .catch(err => {
                console.log(err)
            })
        },

        updateCodeType() {
            var url = this.$vBACKEND_URL + '/index/main.do?method=updateCodeType';
            var pObj = {
                unique_id:this.$store.getters.getUidCompany,
                codeType:this.codeType,
                user_uid:this.$store.getters.getUserUid,
                user_id:this.$store.getters.getUserId,
                user_name:this.$store.getters.getUserName,
            }
            var params = '';
            for(var key in pObj) {
                params += '&' + key + '=' + pObj[key]
            }

            url += params;

            fetch(url)
            .then(res => {
                // store code_type 변경
                this.$store.commit('setCodeType', this.codeType);
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => this.dialogScanSetting = false)
        }
    }
}
</script>
